import React, { ReactNode } from "react";

interface EmailLinkProps {
  email: string;
  subject?: string;
  body?: string;
  children: ReactNode;
}

const ButtonMailTo: React.FC<EmailLinkProps> = ({
  email,
  subject = "",
  body = "",
  children,
}) => {
  // Function to validate email address format
  const isValidEmail = (email: string) => {
    // Simple email format validation using regex
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      console.error("Invalid email address");
      // You can handle invalid email address here (e.g., display an error message)
      return;
    }

    // Open the email link in a new browser window/tab
    const emailLink = `mailto:${encodeURIComponent("abc@outlook.com")}`;
    window.open(emailLink, "_blank", "noopener");
    // window.location.href = 'abc@outlook.com';
  };

  return (
    <a href={`mailto:${email}`} onClick={handleClick} rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default ButtonMailTo;
