import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/hooks";

import dropdownArrowLight from "../../../../assets/images/LightTheme/down_arrow_light.svg";
import dropdownArrowDark from "../../../../assets/images/DarkTheme/down_arrow_dark.svg";
import useClickOutside from "../../../CustomHooks/useClickOutside";
import styles from "./AccountListDropDown.module.scss";
import map from "lodash/map";
import Checkbox from "../../CustomCheckbox/CustomCheckbox";
import Icon from "../../Icon/Icon";
import { debounce, filter } from "lodash";


interface AccountListDropDownProps {
  onABOSelection: (items: string[]) => void;
  reportAboListData: any
}
const AccountListDropDown: React.FC<AccountListDropDownProps> = ({
  onABOSelection,
  reportAboListData
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const dropdownArrow =
    currentTheme === "light" ? dropdownArrowLight : dropdownArrowDark;
  let arr: number[] = new Array(5).fill(0);
  const isLoadingDropdown = false;
  const [selectedItem, setSelectedItem] = useState<any[]>([]);
  const [ABONameList, setABONameList] = useState<any[]>(reportAboListData);
  const [ABONameListRaw, setABONameListRaw] = useState<any[]>(reportAboListData);

  const [searchTerm, setSearchTerm] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const getSearchFilteredABOList = (
    ABOList: string[],
    searchString: String
  ) => {
    if (!searchString || searchString === "") {
      return ABOList;
    }
    const lowerCaseSearchString = searchString?.toLowerCase();

    return ABOList.filter(item => {
      return Object?.values(item)?.some(value =>
        typeof value === "string" && value?.toLowerCase()?.includes(lowerCaseSearchString)
      );
    });
  };

  const searchABOList = async (searchTerm: string) => {
    const filteredABOListData = getSearchFilteredABOList(
      ABONameListRaw,
      searchTerm
    );
    if (!searchTerm || searchTerm === "") {
      setABONameList(ABONameListRaw);
    } else {
      setABONameList(filteredABOListData);
    }
  };

  const handleSelectItem = (clickedItem: { name: string; aboNumber: string | Number }) => {
    const isSelected = selectedItem?.some(
      item => item.name === clickedItem.name && item.aboNumber === clickedItem.aboNumber
    );

    let newlistData: typeof selectedItem;
    if (isSelected) {
      newlistData = selectedItem?.filter(
        item => !(item?.name === clickedItem?.name && item?.aboNumber === clickedItem?.aboNumber)
      );
      setSelectedItem(newlistData);
      onABOSelection(newlistData);
    } else {
      // Add the item to the selection
      newlistData = [...selectedItem, clickedItem];
      setSelectedItem(newlistData);
      onABOSelection(newlistData);
    }
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => setIsOpen(false));

  const searchDebounceABOList =
    debounce(async (term) => {
      if (term?.trim()?.length > 2) {
        searchABOList(term);
      } else if (term?.trim()?.length === 0) searchABOList(term);
    }, 600);

  const handleSearchABO = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    searchDebounceABOList(term);
  };

  return (
    <div ref={dropdownRef} className={`dropdown ${styles.dropdown}`}>
      <div
        className={styles.input_wrapper}
        onClick={toggleDropdown}
        data-testid="toggle_drodpwn_testId"
      >
        <input
          type="text"
          className={`dropdown_input ${styles.dropdown_input} ${isOpen ? "selected_input" : ""}`}
          value={selectedItem?.length > 1 ? `${selectedItem[0].name}, +${selectedItem?.length - 1} ${t("more")}` : selectedItem?.length === 1 ? selectedItem[0]?.name : ''}
          readOnly
          data-testid="dropdown_input_testId"
          placeholder={t("report_dropdown_placeholder")}
        />
        <img
          src={dropdownArrow}
          alt="Downkey"
          className={`${styles.arrow_icon} ${isOpen ? styles.rotate : ""}`}
        />
      </div>

      {isOpen && (
        <div
          className={`dropdown_menu ${styles.dropdown_menu} ${styles.dropdown_pos} ${isLoadingDropdown ? styles.dropdown_loader : ""}`}
          data-testid="report_abo_list_testId"
        >
          {isLoadingDropdown
            ? arr.map((_, index) => (
              <div
                key={index}
                className={`${styles.dropdown_item} ${styles.loader} animate`}
              ></div>
            ))
            :
            <>
              <div className={`search_bar_container ${styles.search_bar_container}`}>
                <Icon
                  name="search"
                  size={18}
                  color={currentTheme === 'light' ? '#2c2c2c' : '#AEAEB2'}
                  className={styles.search_icon}
                />
                <input
                  className={`search_input ${styles.search_input}`}
                  type="text"
                  placeholder={t("report_search_placeholder")}
                  value={searchTerm}
                  onChange={handleSearchABO}
                />
              </div>
              <div className={styles.dropdown_list}>
                {(map(ABONameList, (item, index) => (
                  <div
                    key={item?.aboNumber ?? index}
                    className={`dropdown_item ${styles.dropdown_item} ${selectedItem?.some(
                      selected => selected?.name === item?.name && selected?.aboNumber === item?.aboNumber
                    ) ? `selected ${styles.selected}` : ""}`}
                  >
                    <div className={styles.checkbox_wrapper}>
                      <Checkbox
                        checked={selectedItem?.some(
                          selected => selected?.name === item?.name && selected?.aboNumber === item?.aboNumber
                        )}
                        label={`${item.name} #${item.aboNumber}`}
                        checkboxHandler={() => handleSelectItem(item)}
                        isDisabled={false}
                        screenName='reportbuilder'
                      />
                    </div>
                  </div>
                )))}
              </div>
            </>
          }
        </div>
      )}
    </div>
  );
};

export default AccountListDropDown;
