import React, { useState, useRef, useEffect } from "react";
import ABOProfile from "../ABOProfile/ABOProfile";
import HATVDetails from "../HATVDetailsCard/HATVDetails";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import styles from "./ABOList.module.scss";
import { useAppSelector } from "../../../redux/hooks";
import AILogoLight from "../../../assets/images/LightTheme/ai_logo_1.svg";
import AILogoDark from "../../../assets/images/DarkTheme/ai_logo_1.svg";
import { useTranslation } from "react-i18next";
import { useGetHATVCasesQuery } from "../../../redux/services/hatvCasesAPI";
import HoverArrowLight from "../../../assets/images/LightTheme/hatv_hover_arrow.svg";
import HoverArrowDark from "../../../assets/images/DarkTheme/hatv_hover_arrow.svg";
import { useChatbotQueryMutation } from "../../../redux/services/hatvCasesAPI";
import Icon from "../../common/Icon/Icon";

interface Choice {
  key: string;
  desc: string;
  utterances?: string | null;
}

interface Profile {
  aboNumber: string;
  aboName: string;
  aboCountry: string;
  flag: string;
  kpis: string;
  affiliateCode: string;
}

interface Response {
  question: string;
  response: string;
}

const ABOList: React.FC = () => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);
  const [isChatTriggered, setIsChatTriggered] = useState(false);
  const [responseDataByProfile, setResponseDataByProfile] = useState<
    Record<string, Response[]>
  >({});
  const [profileDataByProfile, setProfileDataByProfile] = useState<
    Record<string, Profile>
  >({});
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const { data: casesData, isLoading } = useGetHATVCasesQuery();
  const profiles: Profile[] = casesData?.body?.cases || [];
  const choices: Choice[] = casesData?.body?.choices || [];
  const ArrowIcon = currentTheme === "light" ? HoverArrowLight : HoverArrowDark;

  const [followUpData, setFollowUpData] = useState<Choice[]>(choices);
  const [isFollowUpLoaded, setIsFollowUpLoaded] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [sendFollowUpQuestion] = useChatbotQueryMutation();
  const [loadingQuestionIndex, setLoadingQuestionIndex] = useState<
    number | null
  >(null);
  const [profileOrder, setProfileOrder] = useState<string[]>([]);

  const handleProfileSelect = (profile: Profile) => {
    setSelectedProfile(profile);
    setIsChatTriggered(true);
    setFollowUpData(choices);
    setIsFollowUpLoaded(false);

    setProfileDataByProfile((prev) => ({
      ...prev,
      [profile.aboNumber]: profile,
    }));

    setResponseDataByProfile((prev) => ({
      ...prev,
      [profile.aboNumber]: prev[profile.aboNumber] || [],
    }));

    setProfileOrder((prevOrder) => {
      const existingIndex = prevOrder.indexOf(profile.aboNumber);
      if (existingIndex !== -1) {
        return [...prevOrder];
      }
      return [...prevOrder, profile.aboNumber];
    });
  };

  const handleSetResponseData: React.Dispatch<
    React.SetStateAction<Response[]>
  > = (value) => {
    if (selectedProfile) {
      setResponseDataByProfile((prev) => ({
        ...prev,
        [selectedProfile.aboNumber]:
          typeof value === "function"
            ? value(prev[selectedProfile.aboNumber] || [])
            : value,
      }));
    }
  };

  const selectedProfileData = selectedProfile
    ? profileDataByProfile[selectedProfile.aboNumber] || selectedProfile
    : null;
  const handleQuestionClick = async (
    affiliateCode: string,
    aboNumber: string,
    choice: Choice,
    index: number
  ) => {
    setLoadingQuestionIndex(index);
    setErrorMessage(null);

    try {
      const result = await sendFollowUpQuestion({
        affiliateCode,
        aboNumber,
        choiceKey: choice.key,
      }).unwrap();

      if (result?.body) {
        setFollowUpData(result.body.choices || []);
        handleSetResponseData((prev) => [
          ...prev,
          { question: choice.desc, response: result?.body?.utterance || "" },
        ]);
      } else {
        setFollowUpData([]);
      }
    } catch (err) {
      setErrorMessage(t("error_loading_followup"));
      console.error("Error fetching follow-up question:", err);
    } finally {
      setLoadingQuestionIndex(null);
    }
  };

  useEffect(() => {
    if (isChatTriggered && contentRef.current) {
      const elementTop =
        contentRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementTop - 256,
        behavior: "smooth",
      });
    }
  }, [isChatTriggered, selectedProfile]);
  useEffect(() => {
    if (!isFollowUpLoaded && choices && choices.length > 0) {
      setFollowUpData(choices);
      setIsFollowUpLoaded(true);
    }
    if (isChatTriggered && selectedProfile) {
      setResponseDataByProfile((prev) => ({
        ...prev,
        [selectedProfile.aboNumber]: prev[selectedProfile.aboNumber] || [],
      }));
    }
  }, [choices, isFollowUpLoaded, selectedProfile, isChatTriggered]);

  return (
    <div className={styles.abo_list_container}>
      <div
        className={styles.abo_list_title}
        style={{
          color: currentTheme === "light" ? "#007775" : "#77CBBF",
          cursor: isChatTriggered ? "default" : "pointer",
        }}
      >
        {t("show_list")}
      </div>
      {isLoading && <LoadingAnimation />}
      {!isLoading && (
        <>
          <div className={styles.abo_textline_wrapper}>
            <img
              src={currentTheme === "light" ? AILogoLight : AILogoDark}
              className={styles.ai_logo}
              alt="logo"
            />
            <div
              className={styles.textline}
              style={{
                color: currentTheme === "light" ? "#2C2C2C" : "#D8D8D9",
              }}
            >
              {profiles.length > 0 ? t("select_abo") : t("no_cases")}
            </div>
          </div>
          <div className={styles.profile_list}>
            <div className={styles.list_title}>
              {profiles.length > 0 ? t("abo_list") + ":" : ""}
            </div>
            {profiles.map((profile) => (
              <ABOProfile
                key={profile.aboNumber}
                profile={profile}
                onSelect={handleProfileSelect}
                isSelected={selectedProfile?.aboNumber === profile.aboNumber}
              />
            ))}
          </div>
        </>
      )}
      {isChatTriggered && selectedProfile && selectedProfileData && (
        <div>
          <div className={styles.abo_profile_details}>
            <div className={styles.details_container}>
              {profileOrder
                .map((aboNumber) => profileDataByProfile[aboNumber])
                .filter(Boolean)
                .map((profile) => (
                  <HATVDetails
                    key={profile.aboNumber}
                    profile={profile}
                    onBack={() => setSelectedProfile(null)}
                    contentRef={contentRef}
                    isSelected={
                      selectedProfile?.aboNumber === profile.aboNumber
                    }
                    followUpChoices={choices}
                    responseData={
                      responseDataByProfile[profile.aboNumber] || []
                    }
                    setResponseData={handleSetResponseData}
                  />
                ))}
            </div>

            {loadingQuestionIndex !== null && (
              <div className={`${styles.followup_text}`}>
                <div className={`question_text ${styles.question_text}`}>
                  {followUpData[loadingQuestionIndex]?.desc || t("loading")}
                </div>
                <LoadingAnimation />
              </div>
            )}
            {/* Follow-Up Questions Section */}
            <div className={styles.followup_heading}>{t("follow_up")}</div>
            {!isLoading && followUpData.length > 0 && !errorMessage ? (
              <div className={styles.followup_grid}>
                {followUpData.map((choice, index) => (
                  <div key={index}>
                    <div
                      className={`followup_item ${styles.followup_item}`}
                      onClick={() =>
                        handleQuestionClick(
                          selectedProfileData?.affiliateCode,
                          selectedProfileData?.aboNumber,
                          choice,
                          index
                        )
                      }
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <span className={`followup_text ${styles.followup_text}`}>
                        {choice.desc}
                      </span>
                      <span
                        className={`followup_arrow ${styles.followup_arrow}`}
                      >
                        {hoveredIndex === index ? (
                          <img
                            src={ArrowIcon}
                            alt="Follow up arrow"
                            className={styles.arrow_image}
                            style={{ width: "40px", height: "40px" }}
                          />
                        ) : (
                          <Icon
                            name="follow_up_arrow"
                            size={40}
                            color={
                              currentTheme === "light" ? "#2C2C2C" : "#D8D8D9"
                            }
                            className={`linkIcon ${styles.linkIcon}`}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              !isLoading && !errorMessage && <div className={styles.no_data}>{t("no_follow_up")}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ABOList;
