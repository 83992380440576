import React from "react";
import styles from "./ABOGrowthCompanion.module.scss";
import LegAnalysis from "./LegAnalysis/LegAnalysis";
import useScroll from "../../../redux/slices/useScrollSlice";
import BusinessPerformance from "./BusinessPerformance/BusinessPerformance";
import Pipeline from "./Pipeline/Pipeline";
import Qualifications from "./Qualifications/Qualifications";
import Income from "./Income/Income";
import { useAppSelector } from "../../../redux/hooks";

interface ABOGrowthCompanionProps {
  activeLink: string;
  refMap: Record<string, React.RefObject<HTMLDivElement>>;
  onSectionChange: (sectionId: string) => void;
}

const ABOGrowthCompanion: React.FC<ABOGrowthCompanionProps> = ({
  activeLink,
  refMap,
  onSectionChange,
}) => {
  const { handleClick } = useScroll(refMap, onSectionChange);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);

  return (
    <div
      className={styles.abo_growth_companion_wrapper}
      data-testid="business_information_plan_testID"
    >
      <div
        className={`content_inner ${styles.abo_growth_companion_inner_wrapper}`}
      >
        {/* <div className={styles.section_wrapper} ref={refMap["key_kpis"]}>
          <KeyKpis />
        </div> */}
        <div
          className={styles.section_wrapper}
          ref={refMap["business_performance"]}
        >
          <BusinessPerformance key={userDetails.aboNumber} />
        </div>
        <div className={styles.section_wrapper} ref={refMap["pipeline"]}>
          <Pipeline category="pipeline" key={userDetails.aboNumber} />
        </div>
        <div className={styles.section_wrapper} ref={refMap["qualifications"]}>
          <Qualifications category="qualifications" key={userDetails.aboNumber} />
        </div>
        <div className={styles.section_wrapper} ref={refMap["structure"]}>
          <LegAnalysis category="structure" key={userDetails.aboNumber} />
        </div>
        <div className={styles.section_wrapper} ref={refMap["income"]}>
          <Income category="income" key={userDetails.aboNumber} />
        </div>
      </div>
    </div>
  );
};

export default ABOGrowthCompanion;
