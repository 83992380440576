import React, { useRef, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import styles from "./HatvComponent.module.scss";
import HatvSubheader from "./HatvSubheaderComponent/HatvSubheader";
import HATVLandingPage from "./HATVLandingPage/HATVLandingPage";
import ABOList from "./ABOList/ABOList";

const HatvComponent: React.FC = () => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const [isABOList, setIsABOList] = useState<boolean>(false);

  const onABOListPageChange = (value: boolean) => {
    setIsABOList(true);
  };
  return (
    <section
      className={`${styles.hatv_main_container}`}
      data-testid="hatv_testID"
    >
      <div className={styles.hatv_sub_header}>
        <HatvSubheader />
      </div>
      <div className={`${styles.hatv_outer_container}`}>
        <div className={styles.landing_page}>
          {!isABOList ? (
            <HATVLandingPage onABOListPageChange={onABOListPageChange} />
          ) : (
            <ABOList />
          )}
        </div>
      </div>
    </section>
  );
};

export default HatvComponent;
