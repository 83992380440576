import React, { useState, useRef, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import styles from "./FollowUpSection.module.scss";
import { useAppSelector } from "../../../redux/hooks";
import remarkGfm from "remark-gfm";
import { useTranslation } from "react-i18next";
import { useChatbotQueryMutation } from "../../../redux/services/hatvCasesAPI";

interface Choice {
  key: string;
  desc: string;
  utterances?: string | null;
}
interface Response {
  question: string;
  response: string;
}
interface FollowUpSectionProps {
  affiliateCode: string;
  aboNumber: string;
  followUpChoices: Choice[];
  responseData: Response[]; // Updated prop
  setResponseData: React.Dispatch<React.SetStateAction<Response[]>>; // Updated prop
}

const FollowUpSection: React.FC<FollowUpSectionProps> = ({
  affiliateCode,
  aboNumber,
  followUpChoices,
  responseData,
  setResponseData,
}) => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const [followUpData, setFollowUpData] = useState<Choice[]>(followUpChoices);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [sendFollowUpQuestion, { isLoading }] = useChatbotQueryMutation();
  const [isFollowUpLoaded, setIsFollowUpLoaded] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isFollowUpLoaded) {
      return;
    }
    setFollowUpData(followUpChoices);
    setIsFollowUpLoaded(true);
  }, [aboNumber, followUpChoices, isFollowUpLoaded]);
  useEffect(() => {
    if (contentRef.current) {
      const elementTop =
        contentRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementTop - 232,
        behavior: "smooth",
      });
    }
  }, [responseData]);

  return (
    <section className={styles.followup_section}>
      <div className={`followup_container ${styles.followup_container}`}>
        {responseData.map((item, index) => (
          <div
            key={index}
            className={`markdown_styled ${styles.markdown_styled} ${styles.response_block}`}
          >
            <div
              className={`question_text ${styles.question_text}`}
              ref={index === responseData.length - 1 ? contentRef : null}
            >
              {item.question}
            </div>
            <div className={styles.response_text}>
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className={styles.tbl_wrapper}
              >
                {item.response}
              </ReactMarkdown>
            </div>
          </div>
        ))}
        {/* Error Message */}
        {errorMessage && (
          <div className={styles.error_container}>
            <p className={styles.error_message}>{errorMessage}</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default FollowUpSection;
