import React from "react";
import styles from "./ABOProfile.module.scss";
import Icon from "../../common/Icon/Icon";
import { useAppSelector } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";
import HoverArrowLight from "../../../assets/images/LightTheme/hatv_hover_arrow.svg";
import HoverArrowDark from "../../../assets/images/DarkTheme/hatv_hover_arrow.svg";
import {KPI_KEYS} from '../../../../src/constants/dataConstants';

interface ABOProfile {
  aboNumber: string;
  aboName: string;
  aboCountry: string;
  flag: string;
  kpis: string;
  affiliateCode: string;
}

interface ABOProfileProps {
  profile?: ABOProfile;
  onSelect?: (profile: ABOProfile) => void;
  isSelected: boolean;
}

const ABOProfile: React.FC<ABOProfileProps> = ({ profile, onSelect, isSelected }) => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const { t } = useTranslation();
  const ArrowIcon = currentTheme === "light" ? HoverArrowLight : HoverArrowDark;
  if (!profile) {
    return <div className={styles.no_profile}>{t("noProfileAvailable")}</div>;
  }

  const kpiDetails = Array.isArray(profile?.kpis)
    ? profile.kpis.map((kpi: KPI) => ({
        name: kpi.kpiName,
        value: kpi.kpiValue,
        flag: kpi.kpiFlag,
      }))
    : [];

  return (
    <div
      className={`profile_card ${styles.profile_card}`}
      style={{
        backgroundColor: isSelected
          ? currentTheme === "light" ? "#F4F4F4" : "#1C1C1E" : "",
        borderColor: isSelected
          ? currentTheme === "light"
            ? "#77CBBF" 
            : "#007775" 
          : "", 
      }}
      onClick={() => {
        if (onSelect) onSelect(profile);
      }}
    >
      <div className={styles.content_container}>
        <div className={styles.abo_name}>{profile.aboName}</div>
        <div className={styles.abo_details}>
          {profile.aboNumber} | {profile.aboCountry}
        </div>
        <div className={styles.violation_status}>
          <div className={styles.violation_text}>{t("violation")}: </div>
          <div className={`flag_wrapper ${styles.flag_wrapper}`}>
          {profile.flag === "red" ? (
            <Icon
              name="flag"
              size={14}
              color={currentTheme === "light" ? "#D91734" : "#F16A81"}
              className={`${styles.flag}`}
            />
          ) : (
            <Icon
              name="flag"
              size={14}
              color={currentTheme === "light" ? "#DA7600" : "#FED773"}
              className={`${styles.flag}`}
            />
          )}
          <div
            className={profile.flag === "red" ? `red_flag ${styles.flag_type}` : `yellow_flag  ${styles.flag_type}`}
          >
            {t(profile.flag)}
          </div>
        </div>
      </div>
      <div className={`violation_desc ${styles.violation_desc}`}
        style={{
          color: currentTheme === "light" ? "#707070" : "#AEAEB2",
        }}
      >
        {kpiDetails?.length > 0 ? (
          kpiDetails.map((kpi, index) => {
            const boldClass = kpi.flag === "Red" || kpi.flag === "Yellow" ? `bold_kpi_value ${styles.bold_kpi_value}` : "";
            return (
              <div key={index} className={styles.kpi_details}>
                <span className={styles.kpi_wrapper}>
                  <span className={boldClass}>{t(`kpi.${KPI_KEYS[kpi.name]}`) || kpi.name}</span>: <span className={boldClass}>{kpi.value ?? "-"}</span>
                  {index < kpiDetails.length - 1 && ","}
                </span>
              </div>
            );
          })
        ) : (
          <div className={styles.no_data}>{t("noDataAvailable")}</div>
        )}
        </div>
      </div>
      <div className={styles.arrow_icon} 
          style={{ opacity: isSelected ? 0 : "" }}
          >
        <img src={ArrowIcon} alt="Arrow Icon" />
      </div>
    </div>
  );
};

export default ABOProfile;
