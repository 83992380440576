import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./axiosBaseQuery/axiosBaseQuery";
import config from "../../config/config";

export const followUpQuestionsAPI = createApi({
  reducerPath: "followUpQuestionsAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: config?.appUrl,
  }),
  endpoints: (builder) => ({
    sendFollowUpQuestion: builder.mutation<any, { question: string }>({
      query: ({ question }) => {
        return {
          url: `/am/hatv/chatbot`,
          method: "POST",
          data: { question },
        };
      },
    }),
  }),
});

export const { useSendFollowUpQuestionMutation } = followUpQuestionsAPI;
