import React, { useState, useEffect } from "react";
import styles from "./CustomCheckbox.module.scss";
import PropTypes from "prop-types";
import UncheckedDark from "../../../assets/images/DarkTheme/UnCheckedIcon.svg";
import CheckedDark from "../../../assets/images/DarkTheme/CheckedIcon.svg";
import CheckedPurple from "../../../assets/images/DarkTheme/CheckboxSelected_purple.svg";
import CheckedPurpleLight from "../../../assets/images/LightTheme/CheckedPurpleLight.svg";
import UncheckedLight from "../../../assets/images/LightTheme/UnCheckedIcon.svg";
import CheckedLight from "../../../assets/images/LightTheme/CheckedIcon.svg";
import { useAppSelector } from "../../../redux/hooks";

interface CheckboxProps {
  checked?: boolean;
  label?: string;
  checkboxHandler?: (checked: boolean) => void;
  isDisabled?: boolean;
  isTagCheck?: boolean;
  screenName?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  label = "",
  checkboxHandler = () => { },
  isDisabled = false,
  isTagCheck = false,
  screenName = '',
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  // currentTheme === "dark" ? CheckedDark : CheckedLight;
  const CheckedIcon =  currentTheme === "dark" ? (screenName === 'reportbuilder' ? CheckedPurple : CheckedDark) : screenName === 'reportbuilder' ? CheckedPurpleLight : CheckedLight;
  const UnCheckedIcon = currentTheme === "dark" ? UncheckedDark : UncheckedLight;
  const onClickHandler = () => {
    if (isDisabled) return;
    setIsChecked((prevChecked) => !prevChecked);
    checkboxHandler(!isChecked);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div className={`${screenName === 'reportbuilder' ? '' : 'checkbox_wrapper'}  ${styles.checkbox_wrapper} ${isTagCheck ? styles.tag_dropdown : ""}`}>
      <div className={styles.checkbox_inner_wrapper}
        style={{ justifyContent: `${screenName === 'reportbuilder' ? 'space-between' : ''}` }}
      >
        <span
          className={`${styles.checkbox_custom} ${isDisabled ? styles.checkbox_custom_disabled : ''}`}
          onClick={onClickHandler}
          data-testid="custom_checkbox"
          style={{ order: `${screenName === 'reportbuilder' ? 2 : 1}` }}
        >
          <img src={isChecked ? CheckedIcon : UnCheckedIcon} alt="checkbox" />
        </span>
        <span className={`checkbox_label ${styles.checkbox_label} ${isChecked ? styles.active_lbl : ""}`}
          style={{ order: `${screenName === 'reportbuilder' ? 1 : 2}` }}
        >{label}</span>
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  checkboxHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default Checkbox;