import React, {useState} from "react";
import LineGraph from "../Graph/Graph";
import styles from "./GraphCard.module.scss";
import dottedLineLight from "../../../assets/images/LightTheme/dottedLight.svg";
import planeLineDark from "../../../assets/images/DarkTheme/lineDark.svg";
import dottedLineDark from "../../../assets/images/DarkTheme/dottedDark.svg";
import planeLineLight from "../../../assets/images/LightTheme/lineLight.svg";
import { useAppSelector } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { CurrencyFormatter } from "../../../utils/currencyFormatter";
import { NumberFormatter } from "../../../utils/NumberFormatter";
interface CardProps {
  lineChartData: LineChartData;
  barChartData?: any;
  activationPeriod?: any;
  isActivartionRate?: boolean;
  currency: string;
  kpiValue?:any
  agcData?: AGCProps;
}

const GraphCard: React.FC<CardProps> = ({ lineChartData, barChartData, activationPeriod, isActivartionRate, currency, kpiValue, agcData }) => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const dottedLine = currentTheme === "light" ? dottedLineLight : dottedLineDark;
  const planeLine = currentTheme === "light" ? planeLineLight : planeLineDark;
  const { t } = useTranslation();
  const currentValue = agcData?.detail?.chart?.chartSummary?.kpiValue;
  const kpiValueDatatype = agcData?.detail?.chart?.chartSummary?.kpiDataType;
  const currentYear = lineChartData?.body?.lines[0]?.lineName;
  const prevYear = lineChartData?.body?.lines[1]?.lineName;
  const kpiVarValue = agcData?.detail?.chart?.chartSummary?.kpiVarianceValue;
  const percentageChange = kpiVarValue === undefined ? undefined : parseFloat(kpiVarValue);
  const absolutePercentageChange = percentageChange !== undefined ? Math.abs(percentageChange) : undefined;
  const kpiVarType= agcData?.detail?.chart?.chartSummary?.kpiVarianceDataType;
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const [userAffiliateCode] = useState(userDetails?.userAffiliateCode || 0);

  // Kpi value formatter added.
  const formattedPercentageChange =
   absolutePercentageChange !== undefined ? 
      kpiVarType === "percentage"
      ? `${absolutePercentageChange}%`
      : kpiVarType === "currency"
      ? `${CurrencyFormatter(currency, absolutePercentageChange)}`
      : `${absolutePercentageChange}`
    : "N/A";

    const formattedValue = currentValue !== undefined
    ? kpiValueDatatype === "percentage"
      ? `${currentValue}%`
      : kpiValueDatatype === "currency"
      ? `${CurrencyFormatter(currency, currentValue)}`
      : kpiValueDatatype === "number" ? `${NumberFormatter(userAffiliateCode, currentValue)}`
      : `${currentValue}`
    : "N/A";

  // Year Month Formatter added.
  const closingMonth = agcData?.detail?.chart?.chartSummary?.closingMonth;
  const comparisonMonth = agcData?.detail?.chart?.chartSummary?.kpiVarianceComparisonMonth;
  const formatMonth = (monthString: string): string => {
    if (!monthString || monthString.length !== 6) return "";
    const year = monthString?.slice(0, 4);
    const month = parseInt(monthString?.slice(4, 6), 10) - 1;
    const date = new Date(Date.UTC(parseInt(year, 10), month, 1));
    const monthName = t(`chartmonths.${date?.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' })}`);
    return `${year} ${monthName}`;
  };
  const closingMonthFormatted = closingMonth ? formatMonth(closingMonth) : null;
  const comparisonMonthFormatted = comparisonMonth ? formatMonth(comparisonMonth) : null;

  if (!lineChartData?.body?.lines?.length) {
    return (
      <div className={`graph_card_container ${styles.graph_card_container}`}>
        {t("noDataAvailable")}
      </div>
    );
  }
  return (
    <div className={`graph_card_container ${styles.graph_card_container}`}>
      {/* Header Section */}
      <div className={`card_header ${styles.card_header}`}>
        <div className={styles.legend_wrapper}>
          <div className={styles.legend}>
            <div className={styles.line_type}>
              <img src={dottedLine} alt="line" />
            </div>
            <div>{prevYear}</div>
          </div>
          <div className={styles.legend}>
            <div className={styles.line_type}>
              <img src={planeLine} alt="line" />
            </div>
            <div>{currentYear}</div>
          </div>
        </div>
      </div>

      {/* Graph Section */}
      <div className={`graph_wrapper ${styles.graph_wrapper}`}>
        <LineGraph lineChartData={lineChartData} barChartData={barChartData} activationPeriod={activationPeriod} isActivartionRate={isActivartionRate} currency={currency} kpiCode={agcData?.kpiCode} />
      </div>

      {/* Footer Section */}
      <div className={`card_footer ${styles.card_footer}`}>
        <div className={styles.month_data}>
          <div className={`current_month_year ${styles.current_month_year}`}>
            {closingMonthFormatted} 
          </div>
          {currentValue !== null ? (
            <div className={`data_value ${styles.data_value}`}>{formattedValue}</div> 
          ) : (
            <div>{t("noDataAvailable")}</div>
          )}
          {kpiVarValue !== null && <div
            className={`value_info_wrapper ${styles.value_info_wrapper} ${
              percentageChange !== undefined && percentageChange >= 0 ? "raised_change" : "fall_change"
            }`}
          >
            <span className={`percentage_value_change ${styles.percentage_value_change}`}>
              {percentageChange !== undefined 
                ? percentageChange >= 0 
                    ? "▲" 
                    : "▼" 
                : ""}{" "}
              {percentageChange === undefined ? "N/A" : formattedPercentageChange}
            </span>

            <span className={`value_diff ${styles.value_diff}`}>
              {t("vs")} {comparisonMonthFormatted}
            </span>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default GraphCard;
