import React from "react";
import styles from "./APInfoDetails.module.scss";
import APDocumentsComponent from "./APDocuments/APDocuments";
import APNotesComponent from "./APNotes/APNotes";
import APBackgroundComponent from "./APBackground/APBackground";

interface APInfoDetailsProps {
  refMap: Record<string, React.RefObject<HTMLDivElement>>;
  onSectionChange: (sectionId: string) => void;
}

const APInfoDetails: React.FC<APInfoDetailsProps> = ({
  refMap,
}) => {
  return (
    <div className={styles.ap_info_details_container}>
      <div className={`content_inner ${styles.inner_wrapper}`}>
        <div className={styles.section_wrapper} ref={refMap["background"]}>
          <APBackgroundComponent />
        </div>
        <div className={styles.section_wrapper} ref={refMap["notes"]}>
          <APNotesComponent />
        </div>
        <div className={styles.section_wrapper} ref={refMap["documents"]}>
          <APDocumentsComponent />
        </div>
      </div>
    </div>
  );
};

export default APInfoDetails;
