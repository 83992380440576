import React, { useEffect, useState } from "react";
import styles from "./ReportLandingPage.module.scss";
import AccountListDropDown from "../../common/Report/AccountListDropdown/AccountListDropDown";
import ReportBuilder from "../ReportBuilder/ReportBuilder";
import Button from "../../common/Button/Button";
import { useTranslation } from "react-i18next";
import { useGetReportBulderAboListQuery } from "../../../redux/services/reportBuilderAPI";
import { useAppSelector } from "../../../redux/hooks";
import { isValidResponse } from "../../../utils/validateAPIResponse";
import { isEmpty } from "lodash";

const mockReportData = [
  {
    heading: "GAR Tracking/QC Analysis",
    content:
      "Lorem ipsum dolor sit amet consectetur.  Lorem ipsum dolor sit amet consectetur. Congue quam sed ut eu sed. Duis tempor sagittis posuere et ac lorem.",
  },
  {
    heading: "ALS Points and Qualifiers Tracking",
    content:
      "Lorem ipsum dolor sit amet consectetur.  Lorem ipsum dolor sit amet consectetur. Congue quam sed ut eu sed. Duis tempor sagittis posuere et ac lorem.",
  },
  {
    heading: "ALS Points and Qualifiers Tracking",
    content:
      "Lorem ipsum dolor sit amet consectetur.  Lorem ipsum dolor sit amet consectetur. Congue quam sed ut eu sed. Duis tempor sagittis posuere et ac lorem.",
  },
];

const ReportLandingPage: React.FC = () => {
  const [isGenerateReport, setIsGenerateReport] = useState<boolean>(true);
  const [isReportTypeSelected, setIsReportTypeSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isReportPage, setIsReportPage] = useState<boolean>(false);
  const [isAPIError, setIsAPIError] = useState(false);
  const [reportAboList, setReportAboList] = useState<any>([]);

  const onABOSelection = (items: string[]) => {
    setSelectedItems(items);
  };

  const { t } = useTranslation();

  const onBack = () => {
    setIsReportTypeSelected(false);
    setIsGenerateReport(true);
    setIsReportPage(false);
  };

  const GenerateReportHandler = () => {
    setIsGenerateReport(false);
    setIsReportPage(true);
  };

  const handleClickCreateNewReport = () => {
    setIsReportTypeSelected(false);
    setIsGenerateReport(true);
    setIsReportPage(false);
    setSelectedItems([]);
  };

  const {
    data: reportAboListData,
    isFetching: isLoadingGetReportsAboList,
    isError,
  } = useGetReportBulderAboListQuery(
    {
      affiliateCode: localStorage?.getItem('userAffiliateCode') || '',
    },
    { refetchOnMountOrArgChange: true, skip: isAPIError }
  );

  const handleAPIError = () => {
    setIsAPIError(true);
  };

  useEffect(() => {
    if (isError) handleAPIError();
  }, [isError]);

  useEffect(() => {
    if (!isEmpty(reportAboListData)) {
      if (isValidResponse(reportAboListData?.statusCode)) {
        setReportAboList(reportAboListData?.body || []);
      } else {
        handleAPIError();
      }
    }
  }, [reportAboListData, isLoadingGetReportsAboList]);

  console.log("selectedItems", selectedItems);

  return (
    <div className={`report_wrapper ${styles.report_wrapper}`}>
      {isReportPage ? (
        <ReportBuilder
          onClickCreateNewReportHandler={handleClickCreateNewReport}
        />
      ) : (
        <>
          <div className={styles.report_header_wrapper}>
            <div className={styles.heading_wrapper}>
              <div className={`report_heading ${styles.report_heading}`}>
                {t("report_builder")}
              </div>
              <div className={`report_subheading ${styles.report_subheading}`}>
                {isReportTypeSelected
                  ? t("select_account")
                  : t("select_report")}
              </div>
            </div>
            {isReportTypeSelected && (
              <div className={`nav_btn_wrapper ${styles.nav_btn_wrapper}`}>
                <Button
                  id="backButton"
                  onClick={onBack}
                  label={t("report_back_button")}
                  style="unfilled"
                />
                <Button
                  id="generateReportButton"
                  onClick={GenerateReportHandler}
                  isDisabled={selectedItems.length === 0}
                  label={t("generate_report")}
                  style="filled"
                />
              </div>
            )}
          </div>
          {isGenerateReport &&
            (!isReportTypeSelected ? (
              <div className={`card_container ${styles.card_container}`}>
                {mockReportData.map((report, index) => (
                  <div
                    key={index}
                    className={`card ${styles.card}`}
                    onClick={() => setIsReportTypeSelected(true)}
                  >
                    <div className={`card_heading ${styles.card_heading}`}>
                      {report.heading}
                    </div>
                    <div className={`card_content ${styles.card_content}`}>
                      {report.content}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.report_builder_section}>
                <AccountListDropDown onABOSelection={onABOSelection} reportAboListData = {reportAboList}/>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default ReportLandingPage;
