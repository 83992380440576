import React, { useRef, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import Icon from "../../common/Icon/Icon";
import styles from "./HatvSubheader.module.scss";
import { useTranslation } from "react-i18next";

const HatvSubheader: React.FC = () => {
    const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
    const { t } = useTranslation();

    return (
        <section className={"hatv_header_wrapper"}>
            <div className={`hatvSubheaderContainer ${styles.hatvSubheaderContainer}`}>
                <Icon
                    name='ai_flower_logo'
                    size={16}
                    color={currentTheme === "light" ? "#1176A9" : "#B2F9FF"}
                    className={styles.ai_flower_logo}
                />
                <span className={styles.headerText}>{t("amway_intelligence")}</span>
            </div>
        </section>
    );
};

export default HatvSubheader;
