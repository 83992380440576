import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./APDocument.module.scss";
import isEmpty from "lodash/isEmpty";
import { useAppSelector } from "../../../../../redux/hooks";
import {
  ITableColumnState,
  deleteDocType,
  toastMessageType,
  IAPDocuementsColumnDetails,
  SuccessionPlanFileType
} from "../../../../../types/types";
import { RootState } from "../../../../../redux/store";
import { sortTableData, findKeysInArray } from "../../../../../utils/common";
import DocumentTabel from "../../../../common/DocumentTable/DocumentTable";
import { useSelector } from "react-redux";
import Modal from "../../../../common/Modal/Modal";

import UploadComponent from "../../../../common/UploadComponent/UploadComponent";
import uploadIconLight from "../../../../../assets/images/LightTheme/upload_light.svg";
import uploadIconDark from "../../../../../assets/images/DarkTheme/upload_dark.svg";
import UploadedDocView from "../../../../common/UploadedDocView/UploadedDocView";
import {
  useGetAPInformationDocsListQuery,
  useDeleteAPInformationDocMutation,
  useUploadAPInformationDocsMutation,
} from "../../../../../redux/services/apInformationdocumentAPI";
import SuccessionDocLoader from "../../../../common/Loaders/SuccessionDocLoader/SuccessionDocLoader";
import ErrorInPageScreen from "../../../../common/ErrorInPageScreen/ErrorInPageScreen";
import { isValidResponse } from "../../../../../utils/validateAPIResponse";
import { disableEnableScroll } from "../../../../../utils/disableEnableScroll";
import ToastMessage from "../../../../common/ToastMessage/ToastMessage";
import DeleteConfirmationPopUp from "../../../../common/DeleteConfirmationPopUp/DeleteConfirmationPopUp";
import { isNull } from "lodash";
import { HttpStatusCode } from "axios";

const customButtonStyles: React.CSSProperties = {
  textAlign: 'center',
  fontFamily: "gt-walsheim-regular",
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '18px'
};

const APDocuments = () => {
  const { t } = useTranslation();
  const [apInformationDocList, setAPInformationDocList] = useState<
  IAPDocuementsColumnDetails[] | []
  >([]);
  const currentTheme = useAppSelector((state: RootState) => state.theme.currentTheme);
  const selectedAP = useAppSelector((state: RootState) => state.apDetails.selectedAP);

  const uploadIcon =
    currentTheme === "light" ? uploadIconLight : uploadIconDark;

  const [modalOpen, setModalOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteDocData, setDeleteDocData] = useState<deleteDocType>();

  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [isSuccessDelete, setIsSccessDelete] = useState<toastMessageType>(undefined);

  const [fileToBeUploaded, setFileToBeUploaded] =
    useState<SuccessionPlanFileType>();
  const [isAPIError, setIsAPIError] = useState<boolean>(false);
  const timeOutPeriod = 3000;

  const { isFetching, data, isError } = useGetAPInformationDocsListQuery(
    {
      apCode: selectedAP?.code,
      year: '0',
    },
    { skip: isAPIError }
  );

  const [columnState, setColumnState] = useState<
    ITableColumnState<IAPDocuementsColumnDetails>[]
  >([]);

  const defaultColumnName =
    columnState[0]?.sortOrder === "nosort" || isEmpty(columnState)
      ? "updatedDate"
      : columnState[0]?.column;
  const defaultSort =
    columnState[0]?.sortOrder === "nosort" || isEmpty(columnState)
      ? "desc"
      : columnState[0]?.sortOrder;
  const [
    updateAPInformationDocsList,
    {
      isLoading: isLoadingDeleteAPInformationDocument,
      isError: isErrorInDelete,
    },
  ] = useDeleteAPInformationDocMutation();

  // Pass exact key names from response to table data
  const tableColumnData = ["apActionPlanId", "name", "size", "updatedDate"];

  const handleAPIError = () => {
    // make this flag true once actual API is integrated
    setIsAPIError(false);
    setAPInformationDocList([]);
  };

  useEffect(() => {
    disableEnableScroll(modalOpen);
  }, [modalOpen]);

  useEffect(() => {
    if (isError || isErrorInDelete) {
      handleAPIError();
    }
  }, [isError, isErrorInDelete]);

  useEffect(() => {
    if (!isEmpty(data)) {
      if (isValidResponse(data?.statusCode)) {
        const prepareTableData = findKeysInArray(
          data?.body ?? [],
          tableColumnData
        );
        // create a common key to use ID's different name
        prepareTableData?.forEach((docRow) => {

          docRow["commonIdKey"] = docRow.apActionPlanId;
        });
        const intialSortData = sortTableData(
          prepareTableData,
          defaultColumnName,
          defaultSort
        );
        setAPInformationDocList(intialSortData);
      } else {
        handleAPIError();
      }
    }
  }, [data, isFetching]);

  const deleteDocument = async (docName: string, id: string | number) => {
    handleDeleteModalToggle();
    setDeleteDocData({
      docName: docName,
      id: id
    })
  };

  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
    setFileToBeUploaded(undefined);
  };

  const handlePersistSelectedFile = (file: SuccessionPlanFileType) => {
    setFileToBeUploaded(file);
  };

  const handleDeleteModalToggle = () => {
    setDeleteModalOpen(!deleteModalOpen);
  }

  const DeleteDocConfirmationModuleContentStyle: React.CSSProperties = {
    width: '384px',
  };

  const confirmDeleteDocs = async () => {
    if (deleteDocData && !isNull(deleteDocData)) {
      handleDeleteModalToggle();
      updateAPInformationDocsList({
        id: deleteDocData.id,
      }).then((resp: any) => {
        if (resp?.data?.statusCode === HttpStatusCode.Ok) {
          setToastMessage("Item successfully deleted.");
          setIsSccessDelete('success');
        } else {
          handleAPIError();
          setToastMessage("Failed to delete an item. Please try again.");
          setIsSccessDelete('error');
        }
      }).finally(() => {
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
          setIsSccessDelete(undefined);
          setToastMessage(null);
        }, timeOutPeriod);
      });

      setDeleteDocData(undefined);
    };

  }

  return (
    <section
      className={`action_plan_document_container ${styles.action_plan_document_container} ${isAPIError ? styles.full_width : ""}`}
    >
      {isFetching || isLoadingDeleteAPInformationDocument ? (
        <SuccessionDocLoader num={1} heading={t("documents")} />
      ) : isAPIError ? (
        <>
          <div className={styles.title} data-testid="action_plan_doc_testId">
            {t("documents")}
          </div>
          <div className={styles.upload_btn_wrapper}>
            <div className={`table_sub_title ${styles.sub_title}`}>
              {t("documentSubTitle")}
            </div>
          </div>
          <ErrorInPageScreen
            handleClickTryAgain={() => {
              setIsAPIError(false);
            }}
          />
        </>
      ) : (
        <>
          <div className={styles.title} data-testid="action_plan_doc_testId">
            {t("AP_info.documents")}
          </div>
          <div className={styles.upload_btn_wrapper}>
            <div className={`table_sub_title ${styles.sub_title}`}>
              {t("AP_info.documentSubTitle")}
            </div>
            <div
              role="button"
              className={styles.icon_wrapper}
              onClick={handleModalToggle}
            >
              <img
                src={uploadIcon}
                alt={t("AP_info.upload")}
                className={styles.upload_icon}
                data-testid="upload_icon_testID"
              />
              <span className={`label ${styles.label}`}>{t("AP_info.upload")}</span>
            </div>
          </div>
          <DocumentTabel
            tableData={apInformationDocList}
            isLoading={isFetching}
            columnState={columnState}
            setColumnState={setColumnState}
            deleteDocument={deleteDocument}
            rowLimit={5}
            columnsToBeVisible={["docName", "date", "actions"]}
            downloadDocUrlParam={"actionplan"}
            screenName={"apInformation"}
            sortNotApplicable={false}
          />
        </>
      )}

      <Modal showDrawer={modalOpen} onClose={handleModalToggle}>
        {isEmpty(fileToBeUploaded) ? (
          <UploadComponent
            handlePersistSelectedFile={handlePersistSelectedFile}
            isSourceAP={true}
          />
        ) : (
          <UploadedDocView
            file={fileToBeUploaded}
            documentTypeList={[]}
            handleCancelCallback={handleModalToggle}
            handleTogglePopupCallback={() => {
              handleModalToggle();
            }}
            handleClickDeleteCallback={() => {
              setFileToBeUploaded(undefined);
            }}
            isEnabledDropdown={false}
            section="analysis_doc"
            uploadMutation={useUploadAPInformationDocsMutation}
            sourceScreenName={"apInformation"}
            setToastMessage={setToastMessage}
            setShowToast={setShowToast}
            setIsSccessUpload={setIsSccessDelete}
          />
        )}
      </Modal>
      {showToast && (
        <ToastMessage
          message={toastMessage}
          subText=""
          toastType={isSuccessDelete}
          showToast={showToast}
          setShowToast={setShowToast}
        />
      )}

      <Modal showDrawer={deleteModalOpen} onClose={handleDeleteModalToggle} customStyle={DeleteDocConfirmationModuleContentStyle}>
        <DeleteConfirmationPopUp id={"apDocDeleteConfirmation"} titleLabel={"Delete Item?"} msgText={"Are you sure you want to permanently delete this item? This action cannot be undone."} buttonLabelOne={"No, Cancel"} buttonLabelTwo={"Yes, Delete"} onCancel={handleDeleteModalToggle} onDelete={confirmDeleteDocs} customStyle={customButtonStyles} />
      </Modal>
    </section>
  );
};

export default APDocuments;
