import React, { useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import Icon from "../../../common/Icon/Icon";
import styles from "./HATVViolationCard.module.scss";
import FollowUpSection from "../../FollowUpQestions/FollowUpQuestion";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useTranslation } from "react-i18next";
import AILogoLight from "../../../../assets/images/LightTheme/ai_logo_1.svg";
import AILogoDark from "../../../../assets/images/DarkTheme/ai_logo_1.svg";
import { KPI_KEYS } from "../../../../constants/dataConstants";

interface ViolationProfile {
  aboNumber: string;
  aboName: string;
  aboCountry: string;
  flag: string;
  kpis: string;
  affiliateCode: string;
}

interface HATVViolationDetailsProps {
  profile: ViolationProfile;
}

const HATVViolationCard: React.FC<HATVViolationDetailsProps> = ({
  profile,
}) => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const Magic = `[Magic](https://magic-gamma/)`;
  const { t } = useTranslation();
  const kpiDetails = Array.isArray(profile?.kpis)
    ? profile.kpis.map((kpi: KPI) => ({
        name: kpi.kpiName,
        value: kpi.kpiValue,
        flag: kpi.kpiFlag,
      }))
    : [];

  return (
    <div className={`violation_container ${styles.violation_container}`}>
        <div className={styles.abo_textline_wrapper}>
            <img
              src={currentTheme === "light" ? AILogoLight : AILogoDark}
              className={styles.ai_logo}
              alt="logo"
            />
            </div>
      <div className={styles.text_wrapper}>
      <div className={styles.abo_name}>{profile.aboName}</div>
      <div className={styles.abo_details}>
        {profile.aboNumber} | {profile.aboCountry}
      </div>
      <div className={styles.violation_status}>
        <div className={styles.violation_text}>{t("violation")}: </div>
        <div className={`flag_wrapper ${styles.flag_wrapper}`}>
          {profile.flag === "red" ? (
            <Icon
              name="flag"
              size={14}
              color={currentTheme === "light" ? "#D91734" : "#F16A81"}
              className={`${styles.flag}`}
            />
          ) : (
            <Icon
              name="flag"
              size={14}
              color={currentTheme === "light" ? "#DA7600" : "#FED773"}
              className={`${styles.flag}`}
            />
          )}
          <div className={profile.flag === "red" ? "red_flag" : "yellow_flag"}>
            {t(profile.flag)}
          </div>
        </div>
      </div>
      <div
        className={`violation_desc ${styles.violation_desc}`}
        style={{
          color: currentTheme === "light" ? "#707070" : "#AEAEB2",
        }}
      >
      {kpiDetails?.length > 0 ? (
          kpiDetails.map((kpi, index) => {
            const boldClass = kpi.flag === "Red" || kpi.flag === "Yellow" ? `bold_kpi_value ${styles.bold_kpi_value}` : "";
            return (
              <div key={index} className={styles.kpi_details}>
                <span className={styles.kpi_wrapper}>
                  <span className={boldClass}>{t(`kpi.${KPI_KEYS[kpi.name]}`) || kpi.name}</span>: <span className={boldClass}>{kpi.value ?? "-"}</span>
                  {index < kpiDetails.length - 1 && ","}
                </span>
              </div>
            );
          })
        ) : (
          <div className={styles.no_data}>{t("noDataAvailable")}</div>
        )}
      </div>
      </div>
      {/* <div>
        <FollowUpSection />
      </div> */}
    </div>
  );
};

export default HATVViolationCard;