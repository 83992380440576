// src/api/HATVCasesAPI.ts
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./axiosBaseQuery/axiosBaseQuery";
import config from "../../config/config";

export const HATVCasesAPI = createApi({
  reducerPath: "HATVCasesAPI",
  baseQuery: axiosBaseQuery({
    baseUrl: config?.appUrl,
  }),
  endpoints: (builder) => ({
    getHATVCases: builder.query<any, void>({
      query: () => ({
        url: `/am/hatv/cases`,
        method: "GET",
      }),
    }),
    chatbotQuery: builder.mutation<any, { affiliateCode: string; aboNumber: string; choiceKey: string }>({
      query: (data) => ({
        url: `/am/hatv/chatbot`,
        method: "POST",
        data,
      }),
    }),
  }),
});

export const { useGetHATVCasesQuery, useChatbotQueryMutation } = HATVCasesAPI;
