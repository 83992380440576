import React from "react";
import styles from "./ReportCard.module.scss";
import { useTranslation } from "react-i18next";

interface ReportCardProps {
  onClickCreateNewReport: () => void;
}

const ReportCard: React.FC<ReportCardProps> = ({
  onClickCreateNewReport = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.report_card}>
      <div className={styles.header}>
        <button
          className={styles.create_report_button}
          onClick={onClickCreateNewReport}
        >
          + {t("create_report")}
        </button>
      </div>
      <div className={styles.report_card}>
        <div className={styles.report_header}>
          <div className={styles.abo_details}>
            <div className={styles.report_title}>
              Cooper George <span className={styles.report_id}>#462567</span>
            </div>
            <div className={styles.report_details}>
              ALS Points and Qualifiers Tracking — 4 Scenarios | Excel Doc — 4.2
              MB
            </div>
          </div>

          <div className={styles.report_actions}>
            <div className={styles.preview_button}>{t("preview")}</div>
            <div className={styles.download_button}>⬇</div>
          </div>
        </div>
        <div className={styles.ai_summary}>
          <div className={styles.summary_header}>
            <span className={styles.summary_icon}>🌸</span>
            <span className={styles.summary_title}>{t("ai_summary")}</span>
            <span className={styles.info_icon}>ℹ️</span>
          </div>
          <p className={styles.summary_text}>
            Lorem ipsum dolor sit amet consectetur. Mauris sociis lacus non
            maecenas tristique maecenas urna. Aliquam fringilla eget orci
            gravida nunc ac congue. Nunc eu adipiscing tristique magna. Ornare
            porttitor proin platea in cras vestibulum facilisi ac.
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold", 
          fontSize: "24px", 
        }}
      >
        Work In Progress
      </div>
    </div>
  );
};

export default ReportCard;
